import type { Client, RequestContext } from "../client";
import type { GitBranchResource } from "../resources";
import type { GitRef } from "../resources/versionControlledResource";
export class ProjectBranchesRepository {
    constructor(private readonly client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    protected apiBaseRoute: string = "~/api/{spaceId}";
    protected apiBaseRouteWithProject: string = `${this.apiBaseRoute}/projects/{projectId}`;
    createBranch(projectId: string, newBranchName: String, baseGitRef: GitRef): Promise<GitBranchResource> {
        const route = `${this.apiBaseRouteWithProject}/git/branches/v2`;
        return this.client.post<GitBranchResource>(this.requestContext, route, { NewBranchName: newBranchName, BaseGitRef: baseGitRef }, { projectId });
    }
}
