import type { Client, RequestContext } from "../client";
import type { ModifyProjectVariablesCommand, PersistenceSettings, VariableSetResource } from "../resources";
import { HasVariablesInGit } from "../resources";
export class ProjectVariablesRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    protected apiBaseRoute: string = "~/api/{spaceId}";
    protected apiBaseRouteWithProject: string = `${this.apiBaseRoute}/projects/{projectId}{/gitRef}`;
    async get(projectId: string, gitRef?: string | undefined): Promise<VariableSetResource> {
        const route = `${this.apiBaseRouteWithProject}/variables`;
        return await this.client.get<VariableSetResource>(this.requestContext, route, {
            projectId,
            gitRef,
        });
    }
    async getSensitive(projectId: string, persistenceSettings: PersistenceSettings): Promise<VariableSetResource> {
        if (!HasVariablesInGit(persistenceSettings)) {
            throw "This should only be used for projects with Git variables";
        }
        const route = `${this.apiBaseRouteWithProject}/variables`;
        return await this.client.get<VariableSetResource>(this.requestContext, route, {
            projectId,
        });
    }
    async modify(projectVariables: ModifyProjectVariablesCommand, projectId: string, gitRef?: string | undefined): Promise<VariableSetResource> {
        const route = `${this.apiBaseRouteWithProject}/variables`;
        await this.client.put<VariableSetResource>(this.requestContext, route, projectVariables, {
            projectId,
            gitRef,
        });
        return this.get(projectId, gitRef);
    }
    async modifySensitive(projectVariables: ModifyProjectVariablesCommand, projectId: string, persistenceSettings: PersistenceSettings): Promise<VariableSetResource> {
        if (!HasVariablesInGit(persistenceSettings)) {
            throw "This should only be used for projects with Git variables";
        }
        const route = `${this.apiBaseRouteWithProject}/variables`;
        await this.client.put<VariableSetResource>(this.requestContext, route, projectVariables, {
            projectId,
        });
        return this.getSensitive(projectId, persistenceSettings);
    }
}
