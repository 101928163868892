import type { Client, RequestContext } from "../client";
import type { DeploymentSettingsResource, ModifyDeploymentSettingsCommand } from "../resources/index";
export class ProjectDeploymentSettingsRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    protected apiBaseRoute: string = "~/api/spaces/{spaceId}";
    protected apiBaseRouteWithProject: string = `${this.apiBaseRoute}/projects/{projectId}{/gitRef}`;
    async get(projectId: string, gitRef?: string | undefined): Promise<DeploymentSettingsResource> {
        const route = `${this.apiBaseRouteWithProject}/deploymentsettings`;
        return await this.client.get<DeploymentSettingsResource>(this.requestContext, route, {
            projectId,
            gitRef,
        });
    }
    async modify(deploymentSettings: ModifyDeploymentSettingsCommand, projectId: string, gitRef?: string | undefined): Promise<DeploymentSettingsResource> {
        const route = `${this.apiBaseRouteWithProject}/deploymentsettings`;
        return await this.client.put<DeploymentSettingsResource>(this.requestContext, route, deploymentSettings, {
            projectId,
            gitRef,
        });
    }
}
