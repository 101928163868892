import type { Client, RequestContext } from "../client";
import type { KubernetesLiveStatusResource } from "../resources/kubernetesLiveStatusResource";
export class KubernetesLiveStatusRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    getForApplicationInstance(projectId: string, environmentId: string, tenantIdOrUntenanted: string) {
        if (tenantIdOrUntenanted === "untenanted")
            return this.client.get<KubernetesLiveStatusResource>(this.requestContext, "~/api/spaces/{spaceId}/{projectId}/livestatus/{environmentId}", {
                projectId: projectId,
                environmentId: environmentId,
            });
        return this.client.get<KubernetesLiveStatusResource>(this.requestContext, "~/api/spaces/{spaceId}/{projectId}/livestatus/{environmentId}/{tenantId}", {
            projectId: projectId,
            environmentId: environmentId,
            tenantId: tenantIdOrUntenanted,
        });
    }
}
