import type { Client, RequestContext } from "../client";
import type { GitRefResource, ProjectResource } from "../resources";
import { DeploymentProcessRepository } from "./deploymentProcessRepository";
import { ScopedProjectVariablesRepository } from "./scopedProjectVariablesRepository";
export class ProjectContextRepository {
    DeploymentProcesses: DeploymentProcessRepository;
    Variables: ScopedProjectVariablesRepository;
    constructor(client: Client, project: ProjectResource, gitRef: GitRefResource | undefined, requestContext: RequestContext) {
        this.DeploymentProcesses = new DeploymentProcessRepository(client, project, gitRef, requestContext);
        this.Variables = new ScopedProjectVariablesRepository(client, project, gitRef, requestContext);
    }
}
