import { css } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { QueryResult } from "@octopusdeploy/octopus-react-client";
import { useExperimentalQuery, useOctopusClient, useOctopusPathResolver } from "@octopusdeploy/octopus-react-client";
import type { ProjectResource, ProjectSummaryResource, ProjectGroupResource, ResourcesById } from "@octopusdeploy/octopus-server-client";
import { Permission, Repository } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { useOctopusSessionPermissions } from "@octopusdeploy/session";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { DropdownSwitcherListLayout } from "../Dropdown/DropdownSwitcherListLayout";
import { DropdownListItem } from "../List/DropdownListItem";
import type { ListElement } from "../List/List";
import { List } from "../List/List";
import { NoSearchResults } from "../Search/NoSearchResults";
import { NoProjects } from "./NoProjects";
import type { ProjectSummaries, ProjectSummary } from "./ProjectSummary";
export interface ProjectsPanelProps {
    projectsState: ProjectsState;
    projectGroupMap: ResourcesById<ProjectGroupResource>;
    getProjectHref: (project: ProjectSummary) => LinkHref;
    filter: string;
    onFilterChange?: (value: string) => void;
    isLoading: boolean;
    errorAlert: React.ReactNode | undefined;
    onProjectClick: () => void;
}
export interface RecentAndAllProjectsState {
    type: "recently-viewed-and-all";
    recentProjects: ProjectSummary[];
    allProjectsPageOne: ProjectSummary[];
    totalProjects: number;
}
export interface EmptyProjectsState {
    type: "empty";
    onAddNewProjectRequested: () => void;
    hasProjectCreatePermission: boolean;
}
export interface FilteredProjectsState {
    type: "filtered";
    filteredProjects: ProjectSummary[];
    totalProjects: number;
}
export interface InitiallyLoading {
    type: "initially-loading";
    recentProjects: ProjectSummary[];
}
export type ProjectsState = RecentAndAllProjectsState | EmptyProjectsState | FilteredProjectsState | InitiallyLoading;
const MAX_PROJECTS_TO_DISPLAY = 50;
const MAX_RECENT_PROJECTS_TO_DISPLAY = 10;
export const ProjectsPanel = React.forwardRef<ListElement, ProjectsPanelProps>(({ projectsState, projectGroupMap, getProjectHref, onFilterChange, isLoading, errorAlert, onProjectClick, filter }, ref) => {
    return (<DropdownSwitcherListLayout ref={ref} searchPlaceholder="Quick search all projects" searchValue={filter} onSearchValueChanged={onFilterChange} searchAccessibleName="Search projects" renderList={(listRef) => getProjectsContent(projectsState, getProjectHref, projectGroupMap, filter, listRef, onProjectClick)} isLoading={isLoading} errorAlert={errorAlert}/>);
});
ProjectsPanel.displayName = "ProjectsPanel";
function getProjectsContent(projectsState: ProjectsState, getProjectHref: (project: ProjectSummary) => LinkHref, projectGroupMap: ResourcesById<ProjectGroupResource>, searchInput: string, listRef: React.Ref<ListElement>, onProjectClick: () => void) {
    const { type } = projectsState;
    switch (type) {
        case "recently-viewed-and-all":
            return <RecentAndAllProjects firstListRef={listRef} getProjectHref={getProjectHref} projectGroupMap={projectGroupMap} projectsState={projectsState} onProjectClick={onProjectClick}/>;
        case "filtered":
            const limitedFilteredResults = projectsState.filteredProjects.slice(0, MAX_PROJECTS_TO_DISPLAY);
            const hasSearchResults = limitedFilteredResults.length > 0;
            const headerText = limitedFilteredResults.length < projectsState.filteredProjects.length
                ? `${limitedFilteredResults.length} results of ${projectsState.filteredProjects.length} for "${searchInput}"`
                : `${limitedFilteredResults.length} results for "${searchInput}"`;
            return (<ProjectsList headerText={hasSearchResults ? headerText : undefined} projects={limitedFilteredResults} projectGroupMap={projectGroupMap} getProjectHref={getProjectHref} accessibleName="Filtered projects" onProjectClick={onProjectClick} emptyView={<NoSearchResults searchInput={searchInput}/>} listRef={listRef}/>);
        case "empty":
            return <NoProjects projectsState={projectsState}/>;
        case "initially-loading":
            return null;
        default:
            exhaustiveCheck(type, `Missing content for projects state type: ${type}`);
    }
}
interface RecentAndAllProjectsProps extends Pick<ProjectsPanelProps, "projectGroupMap" | "getProjectHref" | "onProjectClick"> {
    projectsState: RecentAndAllProjectsState;
    firstListRef: React.Ref<ListElement>;
}
function RecentAndAllProjects({ projectsState, projectGroupMap, getProjectHref, firstListRef, onProjectClick }: RecentAndAllProjectsProps) {
    const { recentProjects, allProjectsPageOne, totalProjects } = projectsState;
    const limitedRecentProjects = recentProjects.slice(0, MAX_RECENT_PROJECTS_TO_DISPLAY);
    const limitedProjects = allProjectsPageOne.slice(0, MAX_PROJECTS_TO_DISPLAY);
    return (<>
            <ProjectsList headerText="Recently viewed" projects={limitedRecentProjects} projectGroupMap={projectGroupMap} getProjectHref={getProjectHref} accessibleName="Recently viewed projects" listRef={limitedRecentProjects.length > 0 ? firstListRef : undefined} emptyView={<span className={listEmptyTextStyles}>Your recently viewed projects will appear here.</span>} onProjectClick={onProjectClick}/>
            <ProjectsList headerText="All projects" headerSubText={`Showing ${limitedProjects.length} of ${totalProjects}`} projects={limitedProjects} projectGroupMap={projectGroupMap} getProjectHref={getProjectHref} accessibleName="All projects" listRef={recentProjects.length > 0 ? undefined : firstListRef} onProjectClick={onProjectClick}/>
        </>);
}
interface ListHeaderProps {
    text: string;
    subText?: string;
}
const ListHeader = ({ text, subText }: ListHeaderProps) => (<div className={listHeaderStyles}>
        <span className={listHeaderTextStyles}>{text}</span>
        {subText && <span className={listHeaderSubTextStyles}>{subText}</span>}
    </div>);
interface ProjectsListProps {
    headerText?: string;
    headerSubText?: string;
    projects: ProjectSummary[];
    projectGroupMap: ResourcesById<ProjectGroupResource>;
    getProjectHref: (project: ProjectSummary) => LinkHref;
    accessibleName: string;
    emptyView?: React.ReactNode;
    listRef: React.Ref<ListElement> | undefined;
    onProjectClick: () => void;
}
const ProjectsList = ({ headerText, headerSubText, projects, projectGroupMap, getProjectHref, accessibleName, emptyView, listRef, onProjectClick }: ProjectsListProps) => {
    const resolvePath = useOctopusPathResolver();
    return (<>
            {headerText && <ListHeader text={headerText} subText={headerSubText}/>}
            {projects.length > 0 ? (<List items={projects} renderListItem={(project, index, tabIndex, ref) => (<DropdownListItem key={project.Id} title={project.Name} description={project.GroupId ? projectGroupMap[project.GroupId]?.Name : undefined} href={getProjectHref(project)} logo={{ href: resolvePath(project.Logo), accessibleName: `${project.Name} logo` }} tabIndex={tabIndex} ref={ref} onClick={onProjectClick}/>)} accessibleName={accessibleName} ref={listRef}/>) : (emptyView)}
        </>);
};
interface ProjectPanelStateOptions {
    recentProjectIds: string[];
    filter: string;
    onAddNewProjectRequested: () => void;
}
export function useProjectPanelState({ filter, recentProjectIds, onAddNewProjectRequested }: ProjectPanelStateOptions) {
    const { refetchProjectGroupMap, projectGroupMap } = useProjectGroupMap();
    const { projects, allProjects, isLoadingProjects, projectsError, refetchProjects, totalProjects } = useFilteredProjectsWithPagingFallback(filter, projectGroupMap);
    const { projects: recentProjects, refetch: refetchRecentProjects } = useProjectsById(allProjects, recentProjectIds);
    const onFetchProjectsRequested = React.useCallback(() => {
        refetchProjects();
        refetchRecentProjects();
        refetchProjectGroupMap();
    }, [refetchProjects, refetchRecentProjects, refetchProjectGroupMap]);
    const repository = useProjectSwitcherRepository();
    const currentPermissions = useOctopusSessionPermissions();
    const hasProjectCreatePermission = currentPermissions.scopeToSpaceAndSystem(repository.spaceId ?? null).isAuthorized({
        permission: Permission.ProjectCreate,
        projectGroupId: "*",
    });
    const isFilteringProjects = filter !== "";
    const projectsState: ProjectsState = getProjectsState(projects, recentProjects, totalProjects, isFilteringProjects, onAddNewProjectRequested, hasProjectCreatePermission);
    return { projectsState, isLoadingProjects, projectsError, onFetchProjectsRequested, projectGroupMap };
}
function getProjectsState(projects: ProjectSummary[] | undefined, recentProjects: ProjectSummary[], totalProjects: number, isFilteringProjects: boolean, onAddNewProjectRequested: () => void, hasProjectCreatePermission: boolean): ProjectsState {
    if (!projects) {
        return {
            type: "initially-loading",
            recentProjects: recentProjects,
        };
    }
    if (isFilteringProjects) {
        return {
            type: "filtered",
            filteredProjects: projects,
            totalProjects,
        };
    }
    if (projects.length === 0) {
        return {
            type: "empty",
            onAddNewProjectRequested,
            hasProjectCreatePermission,
        };
    }
    return {
        type: "recently-viewed-and-all",
        allProjectsPageOne: projects,
        recentProjects,
        totalProjects,
    };
}
function filterProjects(filter: string, projects: ProjectSummary[] | undefined, projectGroupMap: ResourcesById<ProjectGroupResource>) {
    if (!projects || projects.length === 0 || !filter) {
        return projects;
    }
    const matchesFilter = (n: string) => n.toLowerCase().includes(filter.toLowerCase());
    return projects.filter((project) => {
        const { Name: projectName, GroupId: projectGroupId } = project;
        const projectGroupName = projectGroupId ? projectGroupMap[projectGroupId]?.Name : undefined;
        return matchesFilter(projectName) || (projectGroupName && matchesFilter(projectGroupName));
    });
}
function useFilteredProjectsWithPagingFallback(projectsFilter: string, projectGroupMap: ResourcesById<ProjectGroupResource>) {
    const allProjectsQuery = useAllProjectsQuery();
    const shouldQueryServerByPage = allProjectsQuery.data === undefined || allProjectsQuery.data.projects.length === 0;
    const pagedProjectsQuery = useProjectPagedFilterQuery(shouldQueryServerByPage, projectsFilter);
    if (shouldQueryServerByPage) {
        return {
            refetchProjects: pagedProjectsQuery.refetch,
            projects: pagedProjectsQuery.data?.projects,
            allProjects: allProjectsQuery.data?.projects,
            projectsError: pagedProjectsQuery.error ?? allProjectsQuery.error,
            isLoadingProjects: pagedProjectsQuery.isLoading || allProjectsQuery.isLoading,
            totalProjects: pagedProjectsQuery.data?.totalProjects ?? 0,
        };
    }
    return {
        refetchProjects: allProjectsQuery.refetch,
        projects: filterProjects(projectsFilter, allProjectsQuery.data?.projects, projectGroupMap),
        allProjects: allProjectsQuery.data?.projects,
        isLoadingProjects: allProjectsQuery.isLoading,
        projectsError: allProjectsQuery.error,
        totalProjects: allProjectsQuery.data?.totalProjects ?? 0,
    };
}
const PROJECT_FILTER_PAGE_SIZE = 30;
function useAllProjectsQuery(): QueryResult<ProjectSummaries> {
    const repository = useProjectSwitcherRepository();
    return useExperimentalQuery(async () => {
        const projects = await repository.Projects.summaries();
        return {
            projects: projects.map((p) => mapProjectSummaryResourceToModel(p)),
            totalProjects: projects.length,
        };
    }, [repository.Projects]);
}
function useProjectPagedFilterQuery(enabled: boolean, filter: string): QueryResult<ProjectSummaries | undefined> {
    const debouncedFilter = useDebouncedValue(filter);
    const repository = useProjectSwitcherRepository();
    return useExperimentalQuery(async () => {
        if (!enabled) {
            return undefined;
        }
        const data = await repository.Projects.list({ take: PROJECT_FILTER_PAGE_SIZE, ...(debouncedFilter ? { partialName: debouncedFilter } : {}) });
        return {
            projects: data.Items.map((p) => mapProjectResourceToModel(p)),
            totalProjects: data.TotalResults,
        };
    }, [enabled, debouncedFilter, repository.Projects]);
}
function useProjectGroupMap() {
    const repository = useProjectSwitcherRepository();
    const currentPermissions = useOctopusSessionPermissions();
    const hasProjectGroupPermissions = currentPermissions.scopeToSpaceAndSystem(repository.spaceId ?? null).isAuthorized({
        permission: Permission.ProjectGroupView,
        projectGroupId: "*",
    });
    const queryFn = () => (hasProjectGroupPermissions ? repository.ProjectGroups.allById() : Promise.resolve(undefined));
    const { refetch, data, isLoading, error } = useExperimentalQuery(queryFn, [hasProjectGroupPermissions, repository.ProjectGroups]);
    return {
        refetchProjectGroupMap: refetch,
        projectGroupMap: data ?? {},
        projectGroupMapIsLoading: isLoading,
        projectGroupMapError: error,
    };
}
function useProjectsById(allProjects: ProjectSummary[] | undefined, projectIds: string[]) {
    const repository = useProjectSwitcherRepository();
    const { refetch, data } = useExperimentalQuery(async () => {
        const allProjectsById = toLookup(allProjects ?? [], (p) => p.Id);
        const projectIdsToFetch = projectIds.filter((id) => allProjectsById[id] === undefined);
        const fetchedProjectResources = projectIdsToFetch.length > 0 ? await repository.Projects.all({ ids: projectIdsToFetch }) : [];
        const fetchedProjects = fetchedProjectResources.map(mapProjectResourceToModel);
        const fetchedProjectsById = toLookup(fetchedProjects, (p) => p.Id);
        return projectIds.map((id) => fetchedProjectsById[id] ?? allProjectsById[id]).filter(Boolean);
    }, [allProjects, projectIds, repository.Projects]);
    return {
        refetch,
        projects: data ?? [],
    };
}
function useDebouncedValue<T>(value: T) {
    const [debounceValue, setDebounceValue] = useState(value);
    const debouncedSetterRef = useRef(debounce((nextValue) => {
        if (debounceValue !== nextValue) {
            setDebounceValue(nextValue);
        }
    }, 250));
    useEffect(() => {
        const debouncedSet = debouncedSetterRef.current;
        debouncedSet(value);
        return () => {
            debouncedSet.cancel();
        };
    }, [value]);
    return debounceValue;
}
function mapBasePropertiesToModel(project: ProjectResource | ProjectSummaryResource): Omit<ProjectSummary, "Logo"> {
    return {
        Id: project.Id,
        Name: project.Name,
        Slug: project.Slug,
        GroupId: project.ProjectGroupId,
    };
}
function mapProjectResourceToModel(project: ProjectResource): ProjectSummary {
    return {
        ...mapBasePropertiesToModel(project),
        Logo: project.Links.Logo,
    };
}
function mapProjectSummaryResourceToModel(summary: ProjectSummaryResource): ProjectSummary {
    return {
        ...mapBasePropertiesToModel(summary),
        Logo: summary.Logo,
    };
}
function toLookup<T>(values: T[], keySelector: (value: T) => string): Record<string, T> {
    return values.reduce((valuesByKey: Record<string, T>, value) => {
        const key = keySelector(value);
        valuesByKey[key] = value;
        return valuesByKey;
    }, {});
}
function useProjectSwitcherRepository() {
    const client = useOctopusClient();
    return useMemo(() => new Repository(client, {
        abortSignal: new AbortController().signal,
        correlationContext: { component: "ProjectsPanel" },
    }), [client]);
}
const listHeaderStyles = css({
    paddingTop: space[24],
    paddingLeft: space[16],
    paddingBottom: space[8],
});
const listHeaderTextStyles = css({
    font: text.heading.medium,
    color: themeTokens.color.text.primary,
});
const listHeaderSubTextStyles = css({
    font: text.regular.default.medium,
    color: themeTokens.color.text.tertiary,
    marginLeft: space[8],
});
const listEmptyTextStyles = css({
    font: text.regular.default.medium,
    color: themeTokens.color.text.tertiary,
    paddingLeft: space[12],
    paddingBottom: space[12],
});
