/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import { logger } from "@octopusdeploy/logging";
import type { Client, RequestContext } from "../client";
import { Repository } from "../repository";
import type { RouteArgs } from "../resolver";
import type { GetAvailableEnvironmentsForProjectsBffResponse, GetAvailableEnvironmentsForProjectBffResponse, ChannelResource, ConnectTenantsToProjectRequest, DynamicEnvironmentSettings, GetAllProjectSummariesResponse, GetBulkTenantConnectionTaskBffResponse, GetTenantsForProjectBffResponse, GitCommitResource, GitLinkResult, GitPersistenceSettings, GitRefResource, GitTagResource, OverviewReleaseBff, ProjectDynamicEnvironmentOverviewResponse, ProjectIntentsResource, ProjectResource, ProjectStatusResponse, ProjectSummaryResource, ReleaseResource, ResourceCollection, TriggerResource, ValidateGitRefV2Response, CreateSampleProjectBffResponse, GetProjectTemplatesWithValuesBffResponse, GetAvailableEnvironmentsForProjectsAcrossAllSpacesBffResponse, GetCommonTemplatesWithValuesBffResponse, GetProjectsAffectedByChangeBffResponse, ChangedCommonTemplateSets, MigrateRunbooksToGitResponse, GitNamedRefResource, } from "../resources";
import type { ConvertProjectToVersionControlledCommand } from "../resources/convertProjectToVersionControlledCommand";
import type { ConvertProjectToVersionControlledResponse, DeploymentSettingsResource, GitBranchResource, NewProjectResource, ProjectGroupResource, ProjectSettingsMetadata, ProjectSummary, RunbookResource, TriggerActionCategory, TriggerActionType, VersionControlCompatibilityResponse, } from "../resources/index";
import { HasGitPersistenceSettings, HasVcsProjectResourceLinks } from "../resources/index";
import type { GetInsightsForProjectBffResponse } from "../resources/insightsProjectBffResponse";
import type { MigrateProjectRunbooksToGitSummary } from "../resources/migrateProjectRunbooksToGitSummary";
import type { MigrateProjectVariablesToGitCommand, MigrateProjectVariablesToGitResponse } from "../resources/migrateProjectVariablesToGitCommand";
import type { MigrateProjectVariablesToGitSummary } from "../resources/migrateProjectVariablesToGitSummary";
import type { GitRef } from "../resources/versionControlledResource";
import { isGitBranch, isGitCommit, isGitTag, isGitNamedRef } from "../resources/versionControlledResource";
import { BasicRepository } from "./basicRepository";
import type { InsightsGranularity, InsightsTimeRange } from "./insightsReportRepository";
export interface ListProjectsArgs {
    skip?: number;
    take?: number;
    partialName?: string;
    clonedFromProjectId?: string;
}
export enum InsightsTenantFilter {
    UntenantedAndAllTenants = "UntenantedAndAllTenants",
    Untenanted = "Untenanted",
    SingleTenant = "SingleTenant"
}
export const FilterValueStatusValues = {
    Missing: "missing",
    Overridden: "overridden",
    All: "all",
} as const;
export type FilterValueStatus = (typeof FilterValueStatusValues)[keyof typeof FilterValueStatusValues];
export type TemplateValueFilter = {
    filterByName: string | undefined;
    filterByTenant: string | undefined;
    filterByExcludedTenant: string | undefined;
    filterByTags: string[];
    filterByExcludedTags: string[];
    filterByValueStatus: FilterValueStatus | undefined;
};
export interface GetProjectTenantVariablesQueryParameters extends TemplateValueFilter {
    filterByEnvironment: string | undefined;
    filterByExcludedEnvironment: string | undefined;
    filterByTemplateId: string | undefined;
}
export interface GetCommonTemplatesQueryParameters extends TemplateValueFilter {
    filterByLibraryVariableSetId: string | undefined;
}
export const UseDefaultBranch = { UseDefaultBranch: true };
type UseDefaultBranch = typeof UseDefaultBranch;
export type SpecifiedGitRef = string;
export type BranchSpecifier = SpecifiedGitRef | UseDefaultBranch;
export interface PreviewProtectedBranchesRequest {
    defaultBranch: string;
    protectedDefaultBranch: boolean;
    protectedBranchNamePatterns: string[];
}
export interface GetInsightsMetricsForProjectRequest extends Record<string, string | undefined> {
    channelId: string;
    environmentId: string;
    tenantId?: string;
    timeRange: InsightsTimeRange;
    granularity: InsightsGranularity;
    tenantFilter?: InsightsTenantFilter;
    timeZone: string;
}
export interface GetAllRunbooksByProjectRequestV2 {
    Runbooks: RunbookResource[];
}
export interface GetMatchingRefsRequestV1 {
    patterns: string[];
    skip: number;
    take: number;
}
export interface GetMatchingRefsResponseV1 {
    TotalCount: number;
    References: (GitBranchResource | GitTagResource | GitNamedRefResource)[];
}
export class ProjectRepository extends BasicRepository<ProjectResource, NewProjectResource> {
    constructor(client: Client, requestContext: RequestContext) {
        super("Projects", client, requestContext);
    }
    async modify(resource: ProjectResource, args?: {}): Promise<ProjectResource> {
        const project = await super.modify(resource, args);
        this.client.dispatchEvent({ type: "ProjectModified", project });
        return project;
    }
    getDeployments(project: ProjectResource) {
        return this.client.get(this.requestContext, this.client.getLink("Deployments"), { projects: project.Id });
    }
    getDeploymentSettings(project: ProjectResource): Promise<DeploymentSettingsResource> {
        return this.client.get(this.requestContext, project.Links["DeploymentSettings"]);
    }
    getReleases(project: ProjectResource, args?: {
        skip?: number;
        take?: number;
        searchByVersion?: string;
    }): Promise<ResourceCollection<ReleaseResource>> {
        return this.client.get<ResourceCollection<ReleaseResource>>(this.requestContext, project.Links["Releases"], args!);
    }
    async getBffOverviewReleases(project: ProjectResource): Promise<OverviewReleaseBff[]> {
        const resp = await this.client.get<OverviewReleaseBff[]>(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/overview-dashboard-releases", {
            spaceId: project.SpaceId,
            projectId: project.Id,
        });
        return resp;
    }
    getReleaseByVersion(project: ProjectResource, version: string): Promise<ReleaseResource> {
        return this.client.get(this.requestContext, project.Links["Releases"], { version });
    }
    connectTenants(connectTenantResource: ConnectTenantsToProjectRequest, projectId: string): Promise<{}> {
        const args = { projectId };
        return this.client.put(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/tenants", connectTenantResource, args);
    }
    getTenants(projectId: string, pageNumber?: number, pageSize?: number, name?: string): Promise<GetTenantsForProjectBffResponse> {
        const args = { projectId, pageNumber, pageSize, name };
        return this.client.get(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/tenants{?pageNumber,pageSize,name}", args);
    }
    getConnectingTenantsTask(projectId: string, hoursSinceCompleted?: number): Promise<GetBulkTenantConnectionTaskBffResponse> {
        const args = { projectId, hoursSinceCompleted };
        return this.client.get(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/tenants/connection-task{?hoursSinceCompleted}", args);
    }
    list(args?: ListProjectsArgs): Promise<ResourceCollection<ProjectResource>> {
        return this.client.get(this.requestContext, this.client.getLink("Projects"), { ...args });
    }
    listByGroup(projectGroup: ProjectGroupResource): Promise<ResourceCollection<ProjectResource>> {
        return this.client.get(this.requestContext, projectGroup.Links["Projects"]);
    }
    getChannels(project: ProjectResource, skip: number = 0, take: number = Repository.takeAll): Promise<ResourceCollection<ChannelResource>> {
        return this.client.get<ResourceCollection<ChannelResource>>(this.requestContext, project.Links["Channels"], { skip, take });
    }
    getTriggers(project: ProjectResource, gitRef: GitRefResource | string | undefined, skip?: number, take?: number, triggerActionType?: TriggerActionType, triggerActionCategory?: TriggerActionCategory, runbooks?: string[], partialName?: string): Promise<ResourceCollection<TriggerResource>> {
        return this.client.get<ResourceCollection<TriggerResource>>(this.requestContext, project.Links["Triggers"], { skip, take, gitRef: GetGitRefDetails(gitRef), triggerActionType, triggerActionCategory, runbooks, partialName });
    }
    orderChannels(project: ProjectResource) {
        return this.client.post(this.requestContext, project.Links["OrderChannels"]);
    }
    getPulse(projects: ProjectResource[]) {
        const projectIds = projects
            .map((p) => {
            return p.Id;
        })
            .join(",");
        return this.client.get(this.requestContext, this.client.getLink("ProjectPulse"), { projectIds });
    }
    getMetadata(project: ProjectResource): Promise<ProjectSettingsMetadata[]> {
        return this.client.get(this.requestContext, project.Links["Metadata"], {});
    }
    getRunbooks(project: ProjectResource, args?: {
        skip?: number;
        take?: number;
        partialName?: string;
    }): Promise<ResourceCollection<RunbookResource>> {
        return this.client.get<ResourceCollection<RunbookResource>>(this.requestContext, project.Links["Runbooks"], args);
    }
    async getAllRunbooksByProjectV2(project: ProjectResource): Promise<RunbookResource[]> {
        const response = await this.client.get<GetAllRunbooksByProjectRequestV2>(this.requestContext, `~/api/${project.SpaceId}/projects/${project.Id}/runbooks/all/v2`);
        return response.Runbooks;
    }
    getDynamicEnvironmentSettings(project: ProjectResource): Promise<DynamicEnvironmentSettings> {
        return this.client.get<DynamicEnvironmentSettings>(this.requestContext, project.Links["DynamicEnvironmentSettings"]);
    }
    updateDynamicEnvironmentSettings(project: ProjectResource, settings: DynamicEnvironmentSettings): Promise<DynamicEnvironmentSettings> {
        return this.client.post(this.requestContext, project.Links["DynamicEnvironmentSettings"], settings).then(() => this.getDynamicEnvironmentSettings(project));
    }
    async summaries(args?: {
        filterByName?: string;
        filterByProjectGroup?: string;
        filterByExcludedProjectGroup?: string;
        filterByLifecycle?: string;
        filterByExcludedLifecycle?: string;
    }): Promise<ProjectSummaryResource[]> {
        const response = await this.client.get<GetAllProjectSummariesResponse>(this.requestContext, "~/bff/spaces/{spaceId}/projects/summaries{?filterByName,filterByProjectGroup,filterByExcludedProjectGroup,filterByLifecycle,filterByExcludedLifecycle}", args);
        return response.Projects;
    }
    async summariesVersionControlled(): Promise<ProjectSummaryResource[]> {
        const response = await this.client.get<GetAllProjectSummariesResponse>(this.requestContext, "~/bff/spaces/{spaceId}/projects/summaries", { isVersionControlled: true });
        return response.Projects;
    }
    async summariesAcrossAllSpaces(args?: {
        filterByName?: string;
        filterBySpace?: string;
        filterByExcludedSpace?: string;
        filterByProjectGroup?: string;
        filterByExcludedProjectGroup?: string;
    }): Promise<ProjectSummaryResource[]> {
        const response = await this.client.get<GetAllProjectSummariesResponse>(this.requestContext, `~/bff/projects/summaries{?filterByName,filterBySpace,filterByExcludedSpace,filterByProjectGroup,filterByExcludedProjectGroup}`, args);
        return response.Projects;
    }
    getSummary(project: ProjectResource, gitRef: GitRefResource | string | undefined): Promise<ProjectSummary> {
        return this.client.get(this.requestContext, project.Links["Summary"], { gitRef: GetGitRefDetails(gitRef) });
    }
    getBranch(project: ProjectResource, branch: BranchSpecifier): Promise<GitBranchResource> {
        if (HasVcsProjectResourceLinks(project.Links) && HasGitPersistenceSettings(project.PersistenceSettings)) {
            const branchName: string = ShouldUseDefaultBranch(branch) ? project.PersistenceSettings.DefaultBranch : branch;
            return this.client.get(this.requestContext, project.Links.Branches, { name: branchName });
        }
        throw new Error("Cannot retrieve branches from non-VCS projects");
    }
    async previewProtectedBranches(project: ProjectResource, request: PreviewProtectedBranchesRequest): Promise<ResourceCollection<GitBranchResource>> {
        if (HasVcsProjectResourceLinks(project.Links) && HasGitPersistenceSettings(project.PersistenceSettings)) {
            const resp = await this.client.post<{
                Branches: ResourceCollection<GitBranchResource>;
            }>(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/git/preview-protected-branches{?skip,take}", request, {
                spaceId: project.SpaceId,
                projectId: project.Id,
            });
            return resp.Branches;
        }
        throw new Error("Cannot preview protected branches for this project");
    }
    getMatchingGitRefs(project: ProjectResource, request: GetMatchingRefsRequestV1): Promise<GetMatchingRefsResponseV1> {
        return this.client.get(this.requestContext, "~/api/spaces/{spaceId}/projects/{projectId}/git/refs{?patterns,skip,take}", { spaceId: project.SpaceId, projectId: project.Id, ...request });
    }
    getGitRef(project: ProjectResource, gitRef: GitRef): Promise<GitRefResource> {
        if (isGitBranch(gitRef)) {
            return this.getBranch(project, gitRef);
        }
        else if (isGitTag(gitRef)) {
            return this.getTag(project, gitRef);
        }
        else if (isGitCommit(gitRef)) {
            return this.getCommit(project, gitRef);
        }
        else if (isGitNamedRef(gitRef)) {
            return this.getNamedRef(project, gitRef);
        }
        else {
            logger.error("Could not determine the Git ref type of '{GitRef}' on the project '{Project}'.", { GitRef: gitRef, Project: project.Slug });
            throw "Could not determine the Git ref type of '" + gitRef + "' on the project '" + project.Slug + "'.";
        }
    }
    getCommit(project: ProjectResource, hash: string): Promise<GitCommitResource> {
        if (HasVcsProjectResourceLinks(project.Links) && HasGitPersistenceSettings(project.PersistenceSettings)) {
            return this.client.get(this.requestContext, project.Links.Commits, { hash });
        }
        throw new Error("Cannot retrieve commits from non-VCS projects");
    }
    getNamedRef(project: ProjectResource, refName: string): Promise<GitBranchResource> {
        if (HasVcsProjectResourceLinks(project.Links) && HasGitPersistenceSettings(project.PersistenceSettings)) {
            return this.client.get(this.requestContext, project.Links.NamedRefs, { name: refName });
        }
        throw new Error("Cannot retrieve git references from non-VCS projects");
    }
    getTag(project: ProjectResource, tagName: string): Promise<GitTagResource> {
        if (HasVcsProjectResourceLinks(project.Links) && HasGitPersistenceSettings(project.PersistenceSettings)) {
            return this.client.get(this.requestContext, project.Links.Tags, { name: tagName });
        }
        throw new Error("Cannot retrieve tags from non-VCS projects");
    }
    async tryGetBranch(project: ProjectResource, branch: BranchSpecifier): Promise<GitBranchResource | null> {
        try {
            return await this.getBranch(project, branch);
        }
        catch (ex) {
            if (ex.StatusCode === 404) {
                return null;
            }
            throw ex;
        }
    }
    getBranches(project: ProjectResource): Promise<ResourceCollection<GitBranchResource>> {
        if (HasVcsProjectResourceLinks(project.Links)) {
            return this.client.get(this.requestContext, project.Links.Branches);
        }
        throw new Error("Cannot retrieve branches from non-VCS projects");
    }
    getTags(project: ProjectResource): Promise<ResourceCollection<GitTagResource>> {
        if (HasVcsProjectResourceLinks(project.Links)) {
            return this.client.get(this.requestContext, project.Links.Tags);
        }
        throw new Error("Cannot retrieve tags from non-VCS projects");
    }
    searchTags(project: ProjectResource, partialBranchName: string): Promise<ResourceCollection<GitTagResource>> {
        if (HasVcsProjectResourceLinks(project.Links)) {
            return this.client.get(this.requestContext, project.Links.Tags, { searchByName: partialBranchName });
        }
        throw new Error("Cannot retrieve branches from non-VCS projects");
    }
    searchBranches(project: ProjectResource, partialBranchName: string): Promise<ResourceCollection<GitBranchResource>> {
        if (HasVcsProjectResourceLinks(project.Links)) {
            return this.client.get(this.requestContext, project.Links.Branches, { searchByName: partialBranchName });
        }
        throw new Error("Cannot retrieve branches from non-VCS projects");
    }
    async getBranchesFromSettings(project: ProjectResource, settings: GitPersistenceSettings, pagination?: {
        skip?: number;
        take?: number;
    }): Promise<ResourceCollection<GitBranchResource>> {
        const response = await this.client.post<{
            Branches: ResourceCollection<GitBranchResource>;
        }>(this.requestContext, `~/bff/spaces/${project.SpaceId}/projects/${project.Id}/git/branches-by-settings{?skip,take}`, {
            Settings: settings,
        }, pagination);
        return response.Branches;
    }
    convertToVcs(project: ProjectResource, payload: ConvertProjectToVersionControlledCommand): Promise<ConvertProjectToVersionControlledResponse> {
        return this.client.post<ConvertProjectToVersionControlledResponse>(this.requestContext, project.Links.ConvertToGit, payload);
    }
    getMigrateVariablesToGitSummary(project: ProjectResource): Promise<MigrateProjectVariablesToGitSummary> {
        if (project.Links.MigrateVariablesToGit !== undefined) {
            return this.client.get<MigrateProjectVariablesToGitSummary>(this.requestContext, project.Links.MigrateVariablesToGit);
        }
        // Could be because it's in the database, the variables have already been migrated, or the feature isn't enabled
        throw new Error("Migrating variables to Git is not available for this project");
    }
    migrateVariablesToGit(project: ProjectResource, payload: MigrateProjectVariablesToGitCommand): Promise<MigrateProjectVariablesToGitResponse> {
        if (project.Links.MigrateVariablesToGit !== undefined) {
            return this.client.post<MigrateProjectVariablesToGitResponse>(this.requestContext, project.Links.MigrateVariablesToGit, payload);
        }
        // Could be because it's in the database, the variables have already been migrated, or the feature isn't enabled
        throw new Error("Migrating variables to Git is not available for this project");
    }
    migrateRunbooksToGit(project: ProjectResource, command: MigrateProjectVariablesToGitCommand): Promise<MigrateRunbooksToGitResponse> {
        return this.client.post(this.requestContext, "~/api/spaces/{spaceId}/projects/{projectId}/git/migrate-runbooks", command, {
            spaceId: project.SpaceId,
            projectId: project.Id,
        });
    }
    getMigrateRunbooksToGitSummary(project: ProjectResource): Promise<MigrateProjectRunbooksToGitSummary> {
        return this.client.get(this.requestContext, `~/api/spaces/${project.SpaceId}/projects/${project.Id}/git/migrate-runbooks`);
    }
    vcsCompatibilityReport(project: ProjectResource): Promise<VersionControlCompatibilityResponse> {
        return this.client.get(this.requestContext, project.Links["GitCompatibilityReport"]);
    }
    validateGitRef(project: ProjectResource, gitRef: GitRef): Promise<ValidateGitRefV2Response> {
        return this.client.post(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/git/validate/v2", { GitRef: gitRef }, {
            spaceId: project.SpaceId,
            projectId: project.Id,
        });
    }
    getPullRequestLink(project: ProjectResource, gitRef: string): Promise<GitLinkResult> {
        if (HasGitPersistenceSettings(project.PersistenceSettings)) {
            return this.client.get(this.requestContext, `~/bff/spaces/${project.SpaceId}/projects/${project.Id}/pull-request-link{?sourceBranch,targetBranch}`, { sourceBranch: gitRef, targetBranch: project.PersistenceSettings.DefaultBranch });
        }
        throw new Error("Cannot retrieve pull-request link from non-VCS projects");
    }
    // TODO: @team-config-as-code - Our project needs a custom "Delete" link that does _not_ include the GitRef in order for us to
    // successfully hit the /projects/{id} DEL endpoint. For EAP, we're out of time and just hacking it into the frontend client.
    del(project: ProjectResource) {
        if (project.IsVersionControlled) {
            // Our "Self" link should currently include the GitRef. If so, and our last path does not look like our projectId, strip it.
            const selfLinkParts = project.Links.Self.split("/");
            if (selfLinkParts[selfLinkParts.length - 1] !== project.Id) {
                selfLinkParts.pop();
            }
            const selfLink = selfLinkParts.join("/");
            return this.client.del(this.requestContext, selfLink);
        }
        else {
            return this.client.del(this.requestContext, project.Links.Self);
        }
    }
    markAsStale(project: ProjectResource): Promise<void> {
        return this.client.post(this.requestContext, project.Links["RepositoryModified"]);
    }
    getInsightsMetrics(project: ProjectResource, args: GetInsightsMetricsForProjectRequest): Promise<GetInsightsForProjectBffResponse> {
        return this.client.get<GetInsightsForProjectBffResponse>(this.requestContext, `~/bff/spaces/{spaceId}/insights/projects/{projectId}{?projectId,channelId,environmentId,tenantId,tenantFilter,timeRange,granularity,timeZone}`, {
            ...args,
            spaceId: project.SpaceId,
            projectId: project.Id,
        });
    }
    getProjectStatus(projectId: string, spaceId: string): Promise<ProjectStatusResponse> {
        return this.client.get<ProjectStatusResponse>(this.requestContext, "~/bff/spaces/{spaceId}/useronboarding/{projectId}", {
            spaceId,
            projectId,
        });
    }
    getProjectDynamicEnvironmentsOverview(projectId: string, spaceId: string): Promise<ProjectDynamicEnvironmentOverviewResponse> {
        return this.client.get<ProjectDynamicEnvironmentOverviewResponse>(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/environments/dynamic/overview", {
            spaceId,
            projectId,
        });
    }
    getProjectIntents(projectSlug: string, spaceId: string): Promise<ProjectIntentsResource | null> {
        return this.client.get<ProjectIntentsResource>(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectSlug}/intents", {
            spaceId,
            projectSlug,
        });
    }
    setProjectIntents(projectSlug: string, spaceId: string, projectIntents: ProjectIntentsResource): Promise<void> {
        return this.client.post(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectSlug}/intents", {
            projectIntents,
        }, {
            spaceId,
            projectSlug,
        });
    }
    getAvailableEnvironmentsForProject(projectId: string): Promise<GetAvailableEnvironmentsForProjectBffResponse> {
        return this.client.get<GetAvailableEnvironmentsForProjectBffResponse>(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/environments", {
            projectId,
        });
    }
    getAvailableEnvironmentsForProjects(projectIds: string[]): Promise<GetAvailableEnvironmentsForProjectsBffResponse> {
        return this.client.post<GetAvailableEnvironmentsForProjectsBffResponse>(this.requestContext, "~/bff/spaces/{spaceId}/projects/environments", {
            projectIds,
        });
    }
    getAvailableEnvironmentsForProjectsAcrossAllSpaces(projectIds: string[]): Promise<GetAvailableEnvironmentsForProjectsAcrossAllSpacesBffResponse> {
        return this.client.post<GetAvailableEnvironmentsForProjectsAcrossAllSpacesBffResponse>(this.requestContext, "~/bff/projects/environments", {
            projectIds,
        });
    }
    createSampleProject(sampleProjectName: string, sampleProjectReference: string): Promise<CreateSampleProjectBffResponse> {
        return this.client.post<CreateSampleProjectBffResponse>(this.requestContext, "~/bff/spaces/{spaceId}/onboarding/sampleProject/create", {
            sampleProjectName,
            sampleProjectReference,
        });
    }
    getProjectTemplatesWithValues(projectId: string, skip: number, take: number, filter: GetProjectTenantVariablesQueryParameters): Promise<GetProjectTemplatesWithValuesBffResponse> {
        return this.client.get<GetProjectTemplatesWithValuesBffResponse>(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/project-templates{?skip,take,filterByName,filterByTenant,filterByExcludedTenant,filterByEnvironment,filterByExcludedEnvironment,filterByTags,filterByExcludedTags,filterByValueStatus,filterByTemplateId}", {
            projectId,
            skip,
            take,
            ...filter,
        });
    }
    getCommonTemplatesWithValues(projectId: string, skip: number, take: number, filter: GetCommonTemplatesQueryParameters): Promise<GetCommonTemplatesWithValuesBffResponse> {
        return this.client.get<GetCommonTemplatesWithValuesBffResponse>(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/common-templates{?skip,take,filterByName,filterByTenant,filterByExcludedTenant,filterByTags,filterByExcludedTags,filterByValueStatus,filterByLibraryVariableSetId}", {
            projectId,
            skip,
            take,
            ...filter,
        });
    }
    getProjectsAffectedByCommonTemplateChange(projectId: string, changedSets: ChangedCommonTemplateSets[]): Promise<GetProjectsAffectedByChangeBffResponse> {
        return this.client.post<GetProjectsAffectedByChangeBffResponse>(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/affected-by-change", {
            changedSets,
        }, {
            projectId,
        });
    }
    // Primary Git Dependencies use an empty string as a name but the request requires a value otherwise we need
    // to make the property Optional, which we don't want to do. Instead of this, we use the constant "primary" as
    // the marker for when a request wants to select the primary Git Dependency in an action.
    private primaryGitRefName = "primary";
    async getBranchesForGitDependency(requestContext: GitDependencyRequestContext, searchTerm?: string): Promise<ResourceCollection<GitBranchResource>> {
        let routeArgs: RouteArgs = {
            projectId: requestContext.project.Id,
        };
        let path = "~/bff/spaces/{spaceId}/projects/{projectId}/gitDependency/branches";
        if (HasGitPersistenceSettings(requestContext.project.PersistenceSettings)) {
            path = "~/bff/spaces/{spaceId}/projects/{projectId}/{gitRef}/gitDependency/branches";
            routeArgs = {
                ...routeArgs,
                gitRef: requestContext.projectGitRef?.CanonicalName,
            };
        }
        const response = await this.client.post<{
            Resource: ResourceCollection<GitBranchResource>;
        }>(this.requestContext, path, {
            actionName: requestContext.actionName,
            gitDependencyName: requestContext.gitDependencyName || this.primaryGitRefName,
            channelId: requestContext.channelId,
            runbookId: requestContext.runbookId,
            runbookSnapshotId: requestContext.runbookSnapshotId,
            SearchByName: searchTerm,
        }, routeArgs);
        return response.Resource;
    }
    async getBranchForGitDependency(requestContext: GitDependencyRequestContext, branchName: string): Promise<GitBranchResource | null> {
        let routeArgs: RouteArgs = {
            projectId: requestContext.project.Id,
            branchName,
        };
        let path = "~/bff/spaces/{spaceId}/projects/{projectId}/gitDependency/branches/{branchName}";
        if (HasGitPersistenceSettings(requestContext.project.PersistenceSettings)) {
            path = "~/bff/spaces/{spaceId}/projects/{projectId}/{gitRef}/gitDependency/branches/{branchName}";
            routeArgs = {
                ...routeArgs,
                gitRef: requestContext.projectGitRef?.CanonicalName,
            };
        }
        try {
            const response = await this.client.post<{
                Resource: GitBranchResource;
            }>(this.requestContext, path, {
                actionName: requestContext.actionName,
                gitDependencyName: requestContext.gitDependencyName || this.primaryGitRefName,
                channelId: requestContext.channelId,
                runbookId: requestContext.runbookId,
                runbookSnapshotId: requestContext.runbookSnapshotId,
            }, routeArgs);
            return response.Resource;
        }
        catch (ex) {
            if (ex.StatusCode === 404) {
                return null;
            }
            throw ex;
        }
    }
    async getTagsForGitDependency(requestContext: GitDependencyRequestContext, searchTerm?: string): Promise<ResourceCollection<GitTagResource>> {
        let routeArgs: RouteArgs = {
            projectId: requestContext.project.Id,
        };
        let path = "~/bff/spaces/{spaceId}/projects/{projectId}/gitDependency/tags";
        if (HasGitPersistenceSettings(requestContext.project.PersistenceSettings)) {
            path = "~/bff/spaces/{spaceId}/projects/{projectId}/{gitRef}/gitDependency/tags";
            routeArgs = {
                ...routeArgs,
                gitRef: requestContext.projectGitRef?.CanonicalName,
            };
        }
        const response = await this.client.post<{
            Resource: ResourceCollection<GitBranchResource>;
        }>(this.requestContext, path, {
            actionName: requestContext.actionName,
            gitDependencyName: requestContext.gitDependencyName || this.primaryGitRefName,
            channelId: requestContext.channelId,
            runbookId: requestContext.runbookId,
            runbookSnapshotId: requestContext.runbookSnapshotId,
            SearchByName: searchTerm,
        }, routeArgs);
        return response.Resource;
    }
}
function GetGitRefDetails(gitRef: GitRefResource | string | undefined): string {
    if (typeof gitRef === "string" || gitRef instanceof String) {
        return gitRef as string;
    }
    else {
        return gitRef?.CanonicalName as string;
    }
}
export function ShouldUseDefaultBranch(branch: BranchSpecifier): branch is UseDefaultBranch {
    return typeof branch === "object";
}
export type GitDependencyRequestContext = {
    project: ProjectResource;
    projectGitRef: GitRefResource | undefined;
    actionName: string;
    gitDependencyName: string | undefined;
    channelId: string | undefined;
    releaseId: string | undefined;
    runbookId: string | undefined;
    runbookSnapshotId: string | undefined;
};
