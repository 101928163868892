import type { Client, RequestContext } from "../client";
import type { DeploymentProcessResource, ModifyDeploymentProcessCommand } from "../resources/deploymentProcessResource";
export class ProjectDeploymentProcessRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    protected apiBaseRoute: string = "~/api/{spaceId}";
    protected apiBaseRouteWithProject: string = `${this.apiBaseRoute}/projects/{projectId}{/gitRef}`;
    async get(projectId: string, gitRef?: string | undefined): Promise<DeploymentProcessResource> {
        const route = `${this.apiBaseRouteWithProject}/deploymentprocesses`;
        return await this.client.get<DeploymentProcessResource>(this.requestContext, route, {
            projectId,
            gitRef,
        });
    }
    async modify(deploymentProcess: ModifyDeploymentProcessCommand, projectId: string, gitRef?: string | undefined): Promise<DeploymentProcessResource> {
        const route = `${this.apiBaseRouteWithProject}/deploymentprocesses`;
        const updatedDeploymentProcess = await this.client.put<DeploymentProcessResource>(this.requestContext, route, deploymentProcess, {
            projectId,
            gitRef,
        });
        this.client.dispatchEvent({ type: "DeploymentProcessModified" });
        return updatedDeploymentProcess;
    }
}
