import { OctopusError } from "@octopusdeploy/octopus-server-client";
export interface ErrorProperties {
    "Error Code": string | undefined;
    "Error Messages": string[] | undefined;
}
export function getErrorProperties(error: Error | undefined): ErrorProperties {
    if (error === undefined) {
        return {
            "Error Messages": undefined,
            "Error Code": undefined,
        };
    }
    if (error instanceof OctopusError) {
        const errorMessages = error.Errors && error.Errors.length > 0 ? error.Errors : error.ErrorMessage ? [error.ErrorMessage] : [];
        return {
            "Error Code": toErrorCode(error.StatusCode),
            "Error Messages": errorMessages,
        };
    }
    return {
        "Error Code": undefined,
        "Error Messages": error.message ? [error.message] : [],
    };
}
export function toErrorCode(code: number | undefined) {
    return code === undefined ? undefined : `${code} - ${getStatusText(code)}`;
}
function getStatusText(code: number | undefined) {
    if (code === undefined) {
        return undefined;
    }
    switch (code) {
        case 400:
            return "Bad Request";
        case 401:
            return "Unauthorised";
        case 403:
            return "Forbidden";
        case 404:
            return "Not Found";
        case 409:
            return "Conflict";
        case 500:
            return "Server Error";
        case 503:
            return "Service Unavailable";
    }
    return "Error";
}
